import { useContext, useEffect, useState } from "react";

import { Alert, Button, Spinner } from "flowbite-react";
import { FaRegUserCircle } from "react-icons/fa";

import InitialDetails from "../components/InitialDetails";

import rtc from "../services/rtc";
import { Actions } from "../reducers/Booking";
import AppContext from "../context/AppContext";

// Customers
// 0038d00000WaBJPAA3, 0038d00000McHTJAA3, 0038d00000Jqmj2AAB

// Vehicles
// 0vL8d000000ChKvEAK, 0vL8d000000ChPMEA0

const Page = ({ state, dispatch, onError }) => {
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(false);

  const handleNewBooking = () => {
    dispatch({
      type: Actions.Start,
      payload: {},
    });
  };

  const handleExistentBooking = () => {
    dispatch({
      type: Actions.Start,
      payload: {
        existentCustomer: true,
        customer: {
          ...details.customer,
          preferredPhone: 1,
          salutation: "",
          address5: "",
          address6: "",
          courtesyVehicleDriverDOB: "1970-01-01T00:00:00",
        },
        vehicle: { ...details.vehicle, regnum: details.vehicle.regNo },
        opportunity: { ...(details.opportunity || {}) },
      },
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    var customerId = searchParams.get("customer");
    var vehicleId = searchParams.get("vehicle");
    var opportunityId = searchParams.get("opportunity");

    if (!customerId || !vehicleId) {
      dispatch({
        type: Actions.Start,
        payload: {},
      });
    } else {
      rtc
        .getCustomerDetails(customerId, vehicleId, opportunityId)
        .then((res) => setDetails(res))
        .catch((err) => {
          const { status, message } = err;

          if (status >= 500) {
            setError({
              message:
                "Something went wrong. Please refresh the page and try again in a couple of minutes",
            });
          } else {
            setError({ message });
          }
        });
    }
  }, [dispatch]);

  return (
    <>
      {!error && !details && (
        <div className="text-center">
          <Spinner className="h-20 w-20 fill-black" />
        </div>
      )}
      <InitialDetails
        details={details}
        onConfirm={handleExistentBooking}
        onCancel={handleNewBooking}
      />
      {error && (
        <Alert
          color="failure"
          additionalContent={
            <Button color="dark" onClick={handleNewBooking}>
              Start Booking
            </Button>
          }
        >
          <p>{error?.message || ""}</p>
          <p className="pt-4">
            Click below to book a service as a new customer
          </p>
        </Alert>
      )}
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Book Your Service
      </h2>
      <p
        className="pt-4 text-3xl text-slate-500"
        style={{ color: textShadeColor }}
      >
        Servicing your vehicle with manufacturer-approved technicians ensures
        peace of mind, keeping your car reliable and ready for the road when you
        need it most.
      </p>

      <div className="py-4" style={{ color: textColor }}>
        <FaRegUserCircle size="2em" />
      </div>

      <h3 className="text-2xl font-bold" style={{ color: textColor }}>
        Protect your warranty
      </h3>
      <p
        className="pt-4 text-xl text-slate-500"
        style={{ color: textShadeColor }}
      >
        To maintain your vehicle's warranty, it's important to follow the
        recommended service schedule. Skipping a service could lead to
        unexpected repair costs that would otherwise be covered.
      </p>

      <div className="py-4" style={{ color: textColor }}>
        <FaRegUserCircle size="2em" />
      </div>

      <h3 className="text-2xl font-bold" style={{ color: textColor }}>
        Prioritise Safety
      </h3>
      <p
        className="pt-4 text-xl text-slate-500"
        style={{ color: textShadeColor }}
      >
        During every service, we conduct a thorough vehicle health check to
        identify any potential issues early. This helps address safety concerns
        and prevents them from becoming more costly repairs in the future.
      </p>
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
