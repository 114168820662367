import { useState, useMemo, useEffect, useContext } from "react";

import { Button, Datepicker, Select, Spinner } from "flowbite-react";

import { Actions } from "../reducers/Booking";

import Stepper, { STEPS } from "../components/Stepper";
import AppointmentOption from "../components/AppointmentOption";

import rtc from "../services/rtc";
import { FaCar, FaMapMarkerAlt, FaTools } from "react-icons/fa";
import AppContext from "../context/AppContext";

const formatPrice = (price) => {
  return price > 0
    ? "£" + (price || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })
    : "";
};

const padLeft = (v, l, ch) => {
  const str = "" + v;
  if (str.length >= l) {
    return v;
  }
  return new Array(l - str.length).fill(ch).join() + str;
};

const Page = ({ state, dispatch }) => {
  const [facilities, setFacilities] = useState(null);
  const [availabilities, setAvailabilities] = useState(null);

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const fromDate = new Date();
    const toDate = new Date();
    toDate.setDate(toDate.getDate() + 90);

    const from =
      [
        fromDate.getFullYear(),
        padLeft(fromDate.getMonth() + 1, 2, "0"),
        padLeft(fromDate.getDate(), 2, "0"),
      ].join("-") + "T00:00:00";

    const to =
      [
        toDate.getFullYear(),
        padLeft(toDate.getMonth() + 1, 2, "0"),
        padLeft(toDate.getDate(), 2, "0"),
      ].join("-") + "T00:00:00";

    rtc
      .getAppointments(
        state.dealer.dealerId,
        state.vehicle.regNo,
        state.vehicle.model || "",
        state.vehicle.regDate || "",
        state.customer?.dateOfBirth || "",
        from,
        to,
        state.repairOperations
      )
      .then((json) => setAvailabilities(json))
      .catch((err) => console.error(err));

    rtc
      .getFacilities(state.dealer.dealerId)
      .then((json) => setFacilities(json))
      .catch((err) => console.error(err));
  }, [state]);

  const availabilitiesWait = useMemo(
    () => (availabilities == null ? undefined : availabilities.wait || []),
    [availabilities]
  );

  const availabilitiesCourtesyCar = useMemo(
    () => (availabilities == null ? undefined : availabilities.car || []),
    [availabilities]
  );

  const availabilitiesCourtesyLift = useMemo(
    () => (availabilities == null ? undefined : availabilities.lift || []),
    [availabilities]
  );

  const availabilitiesDropoff = useMemo(
    () => (availabilities == null ? undefined : availabilities.dropoff || []),
    [availabilities]
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selected || !selected.date || !selected.time) {
      return;
    }

    dispatch({
      type: Actions.SetAppointment,
      payload: {
        ...selected,
      },
    });
  };

  const [min, max, times] = useMemo(() => {
    if (!selected || !Array.isArray(selected.options)) {
      return [new Date(), new Date(), []];
    }

    const partsStart = selected.options[0].date.split("-");
    const partsEnd =
      selected.options[selected.options.length - 1].date.split("-");

    const selDate = [
      selected.date.getFullYear(),
      padLeft(selected.date.getMonth() + 1, 2, "0"),
      padLeft(selected.date.getDate(), 2, "0"),
    ].join("-");

    const times =
      (selected.options.find((opt) => opt.date === selDate) || {}).time || [];

    return [
      new Date(
        parseInt(partsStart[0], 10),
        parseInt(partsStart[1], 10) - 1,
        parseInt(partsStart[2], 10)
      ),
      new Date(
        parseInt(partsEnd[0], 10),
        parseInt(partsEnd[1], 10) - 1,
        parseInt(partsEnd[2], 10)
      ),
      times,
    ];
  }, [selected]);

  return (
    <>
      <Stepper step={STEPS.Appointment} />

      <h2 className="text-xl block mb-8">Please select your appointment</h2>

      <div>
        {facilities == null ? (
          <div className="text-center">
            <Spinner className="h-16 w-16 fill-black" />
          </div>
        ) : null}
        {facilities?.wait ? (
          <AppointmentOption
            title="Wait at dealership"
            value="wait"
            description={facilities.wait.description}
            selected={selected?.value === "wait"}
            options={availabilitiesWait}
            price={formatPrice(facilities.wait.price)}
            onChange={setSelected}
          />
        ) : null}
        {facilities?.dropoff ? (
          <AppointmentOption
            title="Dropoff"
            value="dropoff"
            description={facilities.dropoff.description}
            selected={selected?.value === "dropoff"}
            options={availabilitiesDropoff}
            price={formatPrice(facilities.dropoff.price)}
            onChange={setSelected}
          />
        ) : null}
        {facilities?.lift ? (
          <AppointmentOption
            title="Courtesy lift"
            value="lift"
            description={facilities.lift.description}
            selected={selected?.value === "lift"}
            options={availabilitiesCourtesyLift}
            price={formatPrice(facilities.lift.price)}
            onChange={setSelected}
          />
        ) : null}
        {facilities?.car ? (
          <AppointmentOption
            title="Courtesy car"
            value="car"
            description={facilities.car.description}
            selected={selected?.value === "car"}
            options={availabilitiesCourtesyCar}
            price={formatPrice(facilities.car.price)}
            onChange={setSelected}
          />
        ) : null}

        {selected && selected.options && (
          <>
            <h2 className="text-xl block mb-4">
              Please confirm your appointment date and timeslot
            </h2>

            <div className="flex justify-center gap-4">
              <div className="w-64">
                <Datepicker
                  minDate={min}
                  maxDate={max}
                  key={selected.value}
                  defaultValue={selected.date}
                  weekStart={1}
                  language="en-GB"
                  className="w-full"
                  onSelectedDateChanged={(date) => {
                    setSelected((prev) => ({ ...prev, date, time: "" }));
                  }}
                  theme={{
                    popup: {
                      root: { base: "absolute z-50 block pt-2 -top-96" },
                    },
                  }}
                />
              </div>

              <div className="w-48">
                <Select
                  className="w-full"
                  value={selected.time}
                  onChange={(evt) =>
                    setSelected((prev) => ({
                      ...prev,
                      time: evt.target.value,
                    }))
                  }
                  key={selected.date.toISOString()}
                >
                  <option></option>
                  {Array.isArray(times) &&
                    times.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="mt-8">
        <Button
          type="button"
          color="dark"
          className="w-full md:w-3/4 md:m-auto"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Your booking
      </h2>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCar size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Vehicle
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.regnum}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaMapMarkerAlt size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Selected Location
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.dealer.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaTools size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Service
      </h3>
      {state.repairOperations.map((ro, idx) => (
        <p
          className="text-xl text-slate-500"
          style={{ color: textShadeColor }}
          key={ro.code}
        >
          {ro.description}
        </p>
      ))}
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
