import { useContext } from "react";

import { Button, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";

import { Actions } from "../reducers/Booking";
import Stepper, { STEPS } from "../components/Stepper";
import { FaCar, FaMapMarkerAlt, FaTools, FaCalendarAlt } from "react-icons/fa";

import { MONTHS, APPOINTMENT_TYPE } from "../constants";
import AppContext from "../context/AppContext";

const Page = ({ state, dispatch }) => {
  const confirm = (payload) => {
    dispatch({
      type: Actions.SetCustomer,
      payload,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      forename: "",
      surname: "",
      postcode: "",

      address1: "",
      address2: "",

      address3: "", // Town/City
      address4: "", // Country

      phone1: "", // Land line
      phone2: "", // Mobile

      email: "",

      preferredPhone: 1,

      salutation: "",
      address5: "",
      address6: "",
      phone1: "",
      phone2: "",
      phone3: "",
      phone4: "",
      courtesyVehicleDriverDOB: "1970-01-01T00:00:00",
    },
  });

  return (
    <>
      <Stepper step={STEPS.Customer} />

      <h2 className="text-xl block mb-8">
        Please enter your contact information
      </h2>

      <form onSubmit={handleSubmit(confirm)}>
        <div className="grid grid-cols-2 gap-x-16 gap-y-4 mt-4">
          <div>
            <label>Title</label>
            <TextInput
              {...register("title", { required: true })}
              className="w-full"
              color={errors?.title ? "failure" : ""}
            />
            {errors?.make && (
              <p className="text-sm text-red-500">Title is required</p>
            )}
          </div>
          <div className="col-start-1">
            <label>First Name</label>
            <TextInput
              {...register("forename", { required: true })}
              className="w-full"
              color={errors?.forename ? "failure" : ""}
            />
            {errors?.forename && (
              <p className="text-sm text-red-500">First Name is required</p>
            )}
          </div>
          <div>
            <label>Last Name</label>
            <TextInput
              {...register("surname", { required: true })}
              className="w-full"
              color={errors?.surname ? "failure" : ""}
            />
            {errors?.surname && (
              <p className="text-sm text-red-500">Last Name is required</p>
            )}
          </div>
          <div>
            <label>Postcode</label>
            <TextInput
              {...register("postcode", { required: true })}
              className="w-full"
              color={errors?.postcode ? "failure" : ""}
            />
            {errors?.postcode && (
              <p className="text-sm text-red-500">Postcode is required</p>
            )}
          </div>
          <div className="col-start-1">
            <label>Address 1</label>
            <TextInput
              {...register("address1", { required: true })}
              className="w-full"
              color={errors?.address1 ? "failure" : ""}
            />
            {errors?.address1 && (
              <p className="text-sm text-red-500">Address 1 is required</p>
            )}
          </div>
          <div>
            <label>Address 2</label>
            <TextInput
              {...register("address2", { required: true })}
              className="w-full"
              color={errors?.address2 ? "failure" : ""}
            />
            {errors?.address1 && (
              <p className="text-sm text-red-500">Address 2 is required</p>
            )}
          </div>
          <div>
            <label>Town / City</label>
            <TextInput
              {...register("address3", { required: true })}
              className="w-full"
              color={errors?.address3 ? "failure" : ""}
            />
            {errors?.address3 && (
              <p className="text-sm text-red-500">Town / City is required</p>
            )}
          </div>
          <div>
            <label>Country</label>
            <TextInput
              {...register("address4", { required: true })}
              className="w-full"
              color={errors?.address4 ? "failure" : ""}
            />
            {errors?.address4 && (
              <p className="text-sm text-red-500">Country is required</p>
            )}
          </div>
          <div>
            <label>Landline</label>
            <TextInput
              {...register("phone1")}
              className="w-full"
              color={errors?.phone1 ? "failure" : ""}
            />
          </div>
          <div>
            <label>Mobile</label>
            <TextInput
              {...register("phone3")}
              className="w-full"
              color={errors?.phone3 ? "failure" : ""}
            />
          </div>
          <div className="col-span-2">
            <label>Email address</label>
            <TextInput
              {...register("email", { required: true })}
              className="w-full"
              color={errors?.email ? "failure" : ""}
            />
            {errors?.email && (
              <p className="text-sm text-red-500">Email is required</p>
            )}
          </div>
        </div>

        <div className="mt-8">
          <Button
            type="submit"
            color="dark"
            className="w-full md:w-3/4 md:m-auto"
          >
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};

const Description = ({ state }) => {
  const {
    brand: { textColor, textShadeColor },
  } = useContext(AppContext);

  return (
    <>
      <h2 className="text-4xl font-bold" style={{ color: textColor }}>
        Your booking
      </h2>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCar size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Vehicle
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.regnum}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.vehicle.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaMapMarkerAlt size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Selected Location
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {state.dealer.description}
      </p>

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaTools size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Service
      </h3>
      {state.repairOperations.map((ro, idx) => (
        <p
          className="text-xl text-slate-500"
          style={{ color: textShadeColor }}
          key={ro.code}
        >
          {ro.description}
        </p>
      ))}

      <div className="pb-4 pt-8" style={{ color: textColor }}>
        <FaCalendarAlt size="2em" />
      </div>
      <h3 className="text-2xl font-bold pb-4" style={{ color: textColor }}>
        Your Appointment
      </h3>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {APPOINTMENT_TYPE[state.availability.value]}
      </p>
      <p className="text-xl text-slate-500" style={{ color: textShadeColor }}>
        {MONTHS[state.availability.date.getMonth()]}{" "}
        {state.availability.date.getDate()},{" "}
        {state.availability.date.getFullYear()} at {state.availability.time}
      </p>
    </>
  );
};

const tuple = { Page, Description };

export default tuple;
