import { Button } from "flowbite-react";

const InitialDetails = ({ details, onConfirm, onCancel }) => (
  <>
    {details && (
      <>
        <div className="mt-8 md:w-3/4 md:m-auto">
          <p className="text-xl font-bold">Is this you?</p>
          <div className="my-8">
            <p className="pt-4 text-xl text-slate-500">
              {details.customer.title} {details.customer.forename}{" "}
              {details.customer.surname}
            </p>
            <p className="pt-4 text-xl text-slate-500">
              {details.vehicle.regNo}
            </p>
            <p className="text-xl text-slate-500">
              {details.vehicle.make} {details.vehicle.model}
            </p>
          </div>
        </div>
        <div className="flex gap-16 justify-center">
          <div className="w-48">
            <Button
              type="submit"
              color="dark"
              className="w-full"
              onClick={onConfirm}
            >
              Yes, this is correct
            </Button>
          </div>
          <div className="w-80">
            <Button
              type="button"
              color="gray"
              className="w-full"
              onClick={onCancel}
            >
              No, manually enter booking details
            </Button>
          </div>
        </div>
      </>
    )}
  </>
);

export default InitialDetails;
